<template>
    <div :class="class_name">
        <!-- Display about of tracked time against the component  -->
        <div
            v-if="time_spent"
            class="time"
            :class="{
                over: time_spent > _time_allocated,
                below:
                    time_spent < _time_allocated &&
                    component.status !== COMPONENT_STATUS.ACTIVE,
                reportable: has_reported_hours_rounding,
            }"
        >
            <strong v-if="has_reported_hours_rounding">
                {{ _time_spent_reportable | milli2duration }}
            </strong>
            <strong v-else>
                {{ time_spent | milli2duration }}
            </strong>
            spent

            <template v-if="_has_allocated">
                of
                <b>
                    {{ _time_allocated | milli2duration }}
                </b>
            </template>
        </div>

        <!-- Otherwise only display about of estimated time  -->
        <div v-else-if="_time_allocated" class="time">
            <strong>{{ _time_allocated | milli2duration }}</strong> estimated
        </div>

        <!-- Invoice time, can be shown conditionally using the "show_invoiced" prop. -->
        <!-- Only visible if higher than 0 -->
        <!-- Visible to super admin only and on rollover -->
        <span
            v-if="show_invoiced && invoiced_hours"
            v-only-super-admin
            class="hidden"
        >
            Invoiced
            <strong style="margin-left: 4px;">
                {{ invoiced_hours | hours2duration }}
            </strong>
        </span>
    </div>
</template>

<script>
import componentSessionMixin from '@/mixins/component.session.mixin';
import componentRecurringMixin from '@/mixins/component.recurring.mixin';
import componentInvoiceMixin from '@/mixins/component.invoice.mixin';
import {momentWithTz} from '@/utils';
import componentMixin from '@/mixins/component.mixin';
import {COMPONENT_STATUS} from '@/enums';

export default {
    name: 'time-spent',
    mixins: [
        componentMixin,
        componentSessionMixin,
        componentRecurringMixin,
        componentInvoiceMixin,
    ],
    props: {
        component: {
            type: Object,
            required: true,
        },
        class_name: {
            type: String,
            default: '',
        },
        show_invoiced: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        COMPONENT_STATUS() {
            return COMPONENT_STATUS;
        },
        selected_block() {
            return this.getRecurringBlockForDate(momentWithTz());
        },
        _time_spent_reportable() {
            if (this.component.recurring && this.selected_block)
                return this.active_time_spent_reportable;

            return this.time_spent_reportable;
        },
        _time_allocated() {
            if (this.component.recurring && this.selected_block)
                return this.time_allocated;

            return this.time_estimate;
        },
        _has_allocated() {
            if (this.selected_block && this.component.recurring) return false;

            return (
                this.component.time_estimate ||
                this.component.time_estimate === 0
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.time {
    font-size: 13px;
    padding-right: 20px;

    @media screen and (max-width: 992px) {
        display: none;
    }

    /**
        Keep in this order
        OVER is more important than REPORTABLE
     */
    &.reportable strong {
        color: $blue;
    }
    &.below strong {
        color: $green;
    }
    &.over strong {
        color: $red;
    }
}
</style>
